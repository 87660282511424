import helpers from "../../../helpers"

export default {
  computed: {
    // Should be replaced by `timeHelpers.formatDateRange(score.started_at, score.finished_at)`
    examDuration() {
      const start = this.score?.started_at
      const end = this.score?.finished_at

      if (!start || !end) return ""

      const isSameDay = helpers.isSameDay(new Date(start), new Date(end))

      const displayStart = this.$moment(start).format("lll")
      const displayEnd = isSameDay ? this.$moment(end).format("LT") : this.$moment(end).format("lll")

      return `${displayStart} → ${displayEnd}`
    },
  },
}
